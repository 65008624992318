import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import SSRProvider from 'react-bootstrap/SSRProvider'

import BasicLearnMorePanel from '../components/basicLearnMorePanel'
import DefaultHeader from '../components/default-header'
import FacultyVideos from '../components/facultyProfileVideos'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import IVVideosModule from '../components/integralVoicesEpsiodeModule'
import JHVideos from '../components/jeanHoustonVideos'
import Navigation from '../components/navigation'
import OverviewVideos from '../components/overviewVideos'
import Seo from '../components/seo'
import SpeakWithAdmissions from '../components/speakWithAdmissions'
import StudentProfileVideos from '../components/studentProfileVideos'

import '../styles/video-center.scss'
import NewsletterSignup from '../components/newsletterSignUp'

 
export default function VideoCenterLeadPage({ data }) {

    const post = data.mdx
    const shortcodes = {
        Container,
        Row,
        Col,
        Button,
        BasicLearnMorePanel,
        OverviewVideos,
        StudentProfileVideos,
        FacultyVideos,
        IVVideosModule,
        JHVideos,
        SpeakWithAdmissions
      }

    return (      
        <>  
            <SSRProvider>
                <Navigation />
                <DefaultHeader headline={post.frontmatter.headline} heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
                <div id="body" className='video-center'>
                    <MDXProvider components={shortcodes}>
                        <MDXRenderer>{post.body}</MDXRenderer>
                    </MDXProvider> 
                </div>
                <Footer />
                <GdprPanel />
                <NewsletterSignup />
            </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`